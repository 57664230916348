<template>
  <v-container id="my-orders">
    <CheckoutTopBar
      @go-back-action="goToMyOrders"
      @show-nav-drawer="showNavDrawer = true"
    />

    <div class="my-page-content">
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-center">
          <div class="order-number">
            <div class="title-7">
              Order Number
            </div>
            <div class="title-3">
              # {{orderDetails.id}}
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="text-center mt-0">
        <v-col cols="12" v-if="orderDetails.receptionType == 0">
          <div class="title-15 mb-2">Delivery to</div>
          <div class="title-13">
            {{orderDetails.address.street}} {{orderDetails.address.streetNumber}}, {{orderDetails.address.postCode}}
          </div>
        </v-col>
        <v-col cols="12" v-else-if="orderDetails.receptionType == 1">
          <div class="title-15 mb-2">Picked up from</div>
          <div class="title-13">
            Eslin 20, Athens
          </div>
        </v-col>

        <v-col cols="12">
          <div class="title-15 mb-2">Date</div>
          <div class="title-13">{{orderDetails.order_date}}</div>
        </v-col>
        <v-col cols="12">
          <div class="title-15 mb-2">Status</div>
          <div class="title-13 text-capitalize">
            {{orderDetails.order_status != undefined ? orderDetails.order_status : ''}}
          </div>
        </v-col>
      </v-row>

      <v-divider class="my-divider mt-13"></v-divider>

      <v-row class="text-center mt-7">
        <v-col cols="12">
          <div class="title-15 mb-2">Items & Payment</div>
        </v-col>
      </v-row>

      <v-list flat>
        <v-list-item
          v-for="(item, index) in orderDetails.dishes"
          :key="`dish-${index}`"
          class="pa-0"
        >
          <v-list-item-content class="text-left py-2">
            <v-list-item-title class="d-flex">
              <div class="title-13 dish-quantity">
                {{item.quantity}}x
              </div>
              <div class="title-12 dish-title">
                {{item.title}}
              </div>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <div class="title-13">
              {{item.totalItemPrice.toFixed(2)}} &euro;
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-list flat>
        <v-divider class="my-divider mt-4 mb-7"></v-divider>

        <v-list-item
          class="pa-0"
        >
          <v-list-item-content class="text-left py-1">
            <v-list-item-title class="d-flex">
              <div class="title-11">
                Total
              </div>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action class="my-2">
            <div class="title-13">
              {{formatAmount(orderDetails.order_total)}} &euro;
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
      
    <NavigationDrawer
      :visible="showNavDrawer"
      @close="showNavDrawer = false"
    />
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import CheckoutTopBar from '@/components/Navigation/CheckoutTopBar';
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';

  export default {
    name: 'OrderDetails',
    components: {
      CheckoutTopBar,
      NavigationDrawer
    },
    computed: {
      ...mapGetters([
        "orderDetails"
      ]),
    },
    data: () => ({
      showNavDrawer: false,
      right_arrow_btn: "$vuetify.icons.right_arrow_btn"
    }),
    methods: {
      ...mapActions([]),
      goToMyOrders() {
        this.$router.push({name: 'my_orders'});
      },
      formatAmount(amount) {
        let result = 0.00;
        if (amount) {
          result = amount.toFixed(2);
        }
        return result;
      }
    }
  };
</script>

<style type="text/css">
  #orders-details {
    padding: 20px;
  }

  .order-number {
    width: 150px;
    height: 144px;
    background-image: url("../assets/order-number-background.svg");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .dish-quantity {
    width: 10%;
  }

  .dish-title {
    width: 90%;
    white-space: break-spaces;
  }

  #my-orders .v-list-item {
    min-height: unset;
  }
</style>
